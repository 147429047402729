<template>
  <div class="record-container">
    <el-row class="header" type="flex" justify="start">
        <el-col :span="24" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员昵称:</div>
              <el-input v-model="distributor_nickname"></el-input>
            </el-col>
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员ID:</div>
              <el-input v-model="distributor_id"></el-input>
            </el-col>
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员手机号:</div>
              <el-input v-model="distributor_mobile"></el-input>
            </el-col>
          </el-row>
        </el-col>
    </el-row>
    <el-row class="header" type="flex" justify="start">
        <el-col :span="24" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" class="finance-top">
              <div class="top-label">分销员邮箱:</div>
              <el-input v-model="distributor_email"></el-input>
            </el-col>
            <el-col :span="6" class="finance-top">
              <div class="top-label">客户ID:</div>
              <el-input v-model="user_id"></el-input>
            </el-col>
            <el-col :span="10" class="finance-top">
              <div class="top-label">{{type!= 2?"支付日期(UTC)":"注册日期(UTC):"}}</div>
              <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        />
            </el-col>
          </el-row>
        </el-col>
    </el-row>
     <el-row class="header create-option" type="flex" justify="start">
        <el-button type="primary" @click="handleExport">导出Excel</el-button>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleClear">清空</el-button>
         </el-row>

    <div class="content">
      <el-table :data="list" border stripe>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlanReward, exportPlanReward } from "@/api/developer.js";

import config from "@/configs";
export default {
  data() {
    return {
      id:"",
      list:[],
      total:0,
      currentPage: 1,
      pageSize: 20,
      distributor_nickname:"",
      distributor_id:"",
      distributor_mobile:"",
      distributor_email:"",
      user_id:"",
      time:[],
      type:"",
      columns:[],
      columns1: [
        {
          id: "id",
          label: "序号",
        },
        {
          id: "distributor_nickname",
          label: "分销员昵称",
        },
        {
          id: "distributor_id",
          label: "分销员Id",
        },
        {
          id: "distributor_mobile",
          label: "分销员手机",
        },
        {
          id: "distributor_email",
          label: "分销员邮箱",
        },
        {
          id: "user_nickname",
          label: "客户昵称",
        },
        {
          id: "user_id",
          label: "客户ID",
        },
        {
          id: "pay_amount",
          label: "支付金额",
        },
        {
          id: "reward_currency",
          label: "金额币种",
        },
        {
          id: "happened_at",
          label: "支付时间(UTC)",
        },
        {
          id: "plan_reward_rate",
          label: "返佣比例",
        },
        {
          id: "reward",
          label: "返佣奖励",
        },
      ],
      columns2: [
        {
          id: "id",
          label: "序号",
          width:"80px"
        },
        {
          id: "distributor_nickname",
          label: "分销员昵称",
        },
        {
          id: "distributor_id",
          label: "分销员Id",
        },
        {
          id: "distributor_mobile",
          label: "分销员手机",
        },
        {
          id: "distributor_email",
          label: "分销员邮箱",
        },
        {
          id: "user_nickname",
          label: "客户昵称",
        },
        {
          id: "user_id",
          label: "客户ID",
        },
        {
          id: "happened_at",
          label: "注册时间(UTC)",
           width:"150px"
        },
         {
          id: "reward",
          label: "奖励金额",
        },
        {
          id: "reward_currency",
          label: "金额币种",
        },
      ],
      columns3: [
        {
          id: "id",
          label: "序号",
        },
        {
          id: "distributor_nickname",
          label: "分销员昵称",
        },
        {
          id: "distributor_id",
          label: "分销员Id",
        },
        {
          id: "distributor_mobile",
          label: "分销员手机",
        },
        {
          id: "distributor_email",
          label: "分销员邮箱",
        },
        {
          id: "user_nickname",
          label: "客户昵称",
        },
        {
          id: "user_id",
          label: "客户ID",
        },
        {
          id: "happened_at",
          label: "支付时间(UTC)",
        },
        {
          id: "reward",
          label: "奖励金额",
        },
        {
          id: "reward_currency",
          label: "金额币种",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    const id = this.$route.query.id;
    this.id = id;
    this.type = this.$route.query.type;
    this.columns = this[`columns${this.$route.query.type}`]
    // console.log("=====record",id,this.$route.query.type)
    this.getRecords();
  },
  methods: {
    async getRecords() {
      const params = {
        plan_id:this.id,
        page:this.currentPage,
        page_size:this.pageSize,
        distributor_nickname: this.distributor_nickname, // 分销员昵称
        distributor_id: this.distributor_id, // 分销员id
        distributor_email: this.distributor_email, // 分销员邮箱
        distributor_mobile: this.distributor_mobile, // 分销员手机
        user_id: this.user_id, // 客户id
        time_start: this.time[0],    // 支付时间（注册时间）
        time_end: this.time[1]
        }
      try {
          const { data } = await getPlanReward(params);
          this.list = data.data.data_list;
        this.total = data.data.total_num;
        } catch (error) {
          console.log(error);
        }
    },
    async getExoprt() {
      const params = {
        plan_id:this.id,
        distributor_nickname: this.distributor_nickname, // 分销员昵称
        distributor_id: this.distributor_id, // 分销员id
        distributor_email: this.distributor_email, // 分销员邮箱
        distributor_mobile: this.distributor_mobile, // 分销员手机
        user_id: this.user_id, // 客户id
        time_start: this.time[0],    // 支付时间（注册时间）
        time_end: this.time[1]
        }
      try {
        const { data } = await exportPlanReward(params);
         data.data?window.open(data.data):this.$message.error("暂无下载链接");
        } catch (error) {
          console.log(error);
        }
    },
    handleSearch(){
      this.currentPage = 1
      this.getRecords();
    },
    handleExport(){
      this.getExoprt();
    },
    handleClear(){
      this.distributor_nickname="";
      this.distributor_id="";
      this.distributor_mobile="";
      this.distributor_email="";
      this.user_id="";
      this.time=[];
      this.getRecords();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getRecords();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getRecords();
    },
  },
};
</script>

<style lang="less" scoped>
.record-container {
  width: 100%;

  .header {
    padding: 20px;
  }

  .search-input {
    width: 300px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .finance-top{
  display: flex;
  align-items: center;
  font-size: 14px;
  .top-label{
    min-width: 100px;
  }
  .el-range-editor{
    width: 100%;
  }
}

  .content {
    padding: 0 20px 20px;
  }
}
</style>
